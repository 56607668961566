import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import BrandButton from "./BrandButton";
import BurgerButton from "./BurgerButton";

interface IProps {
  handleClick: (link: string) => void;
  isProject: boolean;
}

const Header = ({ handleClick, isProject }: IProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <BrandButton isProject={isProject} handleClick={handleClick} />
      <BurgerButton open={open} setOpen={setOpen} />
      <StyledHeader open={open}>
        <div className="brand">
          <div className="brand__inner">
            <Link to="/" onClick={() => handleClick("/")}>
              <svg
                onClick={() => setOpen(false)}
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="70"
                viewBox="0 0 150 130"
              >
                <defs>
                  <clipPath id="clip-Custom_Size_1">
                    <rect width="150" height="130" />
                  </clipPath>
                </defs>
                <g
                  id="Custom_Size_1"
                  data-name="Custom Size – 1"
                  clipPath="url(#clip-Custom_Size_1)"
                >
                  <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M71.577,0l45.549-124.6H95.677L61.7-20.726h-.482L26.269-124.6H3.374L49.646,0Z"
                    transform="translate(-0.25 127.097)"
                    fill="#ffffff"
                  />
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M71.577,0l45.549-124.6H95.677L61.7-20.726h-.482L26.269-124.6H3.374L49.646,0Z"
                    transform="translate(29.75 127.097)"
                    fill="#f9d342"
                  />
                </g>
              </svg>
            </Link>
            <div>
              <strong>Vince</strong>
            </div>
            <p>Web Developer</p>
          </div>
        </div>
        <nav>
          <ul>
            <li onClick={() => setOpen(false)}>
              <Link
                to="/"
                onClick={() => handleClick("/")}
                activeClassName="active"
              >
                Home
              </Link>
            </li>
            <li onClick={() => setOpen(false)}>
              <Link
                to="/work"
                onClick={() => handleClick("/work")}
                activeClassName="active"
                partiallyActive={true}
              >
                Work
              </Link>
            </li>
            <li onClick={() => setOpen(false)}>
              <Link
                to="/contact"
                onClick={() => handleClick("/contact")}
                activeClassName="active"
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <div></div>
      </StyledHeader>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

const StyledHeader = styled.header<{ open: boolean }>`
  color: var(--white);
  background: #181818;
  position: fixed;
  height: 100%;
  width: 130px;
  text-align: center;
  z-index: 10;

  display: grid;
  grid-template-rows: 1fr auto 1fr;
  grid-template-columns: 1fr;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;

  & > * {
    width: 100%;
  }

  .brand__inner {
    padding: 20px;
    background-color: #111;

    strong {
      font-size: 1.4em;
      font-family: Arial, Helvetica, sans-serif;
    }

    p {
      font-size: 12px;
      color: #b2b2b2;
      margin: 10px 0 5px;
    }
  }

  nav {
    justify-self: center;
  }

  li {
    list-style: none;
    border-bottom: 1px solid #333;
    font-size: 1.1em;
    color: #b2b2b2;
    transition: color 0.2s ease-in-out;
  }

  li:first-child {
    border-top: 1px solid #333;
  }

  li:hover {
    color: var(--primary-color);
    color: white;
  }

  li a {
    display: block;
    padding: 15px;
  }

  .active {
    color: var(--primary-color);
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
    left: -100%;
    transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(-0%)")};
    transition: transform 0.3s ease-in-out;
    transition: transform 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

export default Header;
