import styled from "styled-components";
import noise from "../assets/noise.jpg";
interface IMainWrapperProps {
  scanlines: boolean;
}
export const MainWrapper = styled.div<IMainWrapperProps>`
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin-left: 130px;
  transition: opacity 0.2s ease-in-out;

  & > svg {
    height: 0;
    position: fixed;
  }

  /* &.anim { */
  /* opacity: 0.4;
  animation: noiseAnim 0.2s linear infinite;
  filter: url("#shake0");
  background: url(${noise});

  .glitch {
    top: calc(40%);
  } */
  /* } */

  &.anim {
    opacity: 0.4;
    animation: distort 0.2s linear forwards;
  }

  &.anim::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    animation: noiseAnim 0.2s linear infinite;
    background: url(${noise});
  }

  /* @keyframes distort {
    0% {
      filter: url("#shake0");
      transform: translate(-5px, 5px);
    }
    25% {
      filter: url("#shake1");
      transform: translate(10px, -5px);
    }
    50% {
      filter: url("#shake2");
      transform: translate(-5px, -5px);
    }
    75% {
      filter: url("#shake3");
    }
    100% {
      filter: url("#shake4");
    }
  } */
  @keyframes distort {
    0% {
      /* filter: url("#shake0"); */
      transform: translate(-5px, 0);
    }
    25% {
      /* filter: url("#shake1"); */
      transform: translate(5px, 0);
    }
    50% {
      /* filter: url("#shake2"); */
      transform: translate(-5px, 0);
    }
    75% {
      /* filter: url("#shake3"); */
    }
    100% {
      /* filter: url("#shake4"); */
    }
  }
  @keyframes noiseAnim {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 100% 100%;
    }
  }

  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    display: ${({ scanlines }) => (scanlines ? "block" : "none")};
    position: absolute;
    width: 100%;
    height: 104%;
    top: 0;
    left: 0;
    z-index: -1;

    background: repeating-linear-gradient(
      to bottom,
      transparent 0%,
      rgba(255, 255, 255, 0.05) 0.5%,
      transparent 0.75%
    );

    animation: fudge 6s ease-in-out alternate infinite;
  }

  @media screen and (max-width: 1199px) {
    margin-left: 0;
  }
`;

export const Container = styled.div<{ paddingBottom?: string }>`
  margin: 0 auto;
  padding: 5vw;
  min-height: 100vh;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1199px) {
    padding: calc(55px + 5vw) 5vw calc(55px + 5vw);
    padding-bottom: ${({ paddingBottom }) =>
      paddingBottom ? paddingBottom : "calc(55px + 5vw)"};
  }
`;

interface IBoxProps {
  flex: any;
  wrap: any;
  alignContent: any;
  justifyContent: any;
  margin: any;
  width: any;
  mobile: boolean;
}
export const Row = styled.div<IBoxProps>`
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : "initial")};
  flex-direction: row;
  flex-wrap: ${(props) => (props.wrap ? props.wrap : "initial")};
  align-content: ${(props) =>
    props.alignContent ? props.alignContent : "initial"};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "initial"};
  @media screen and (max-width: 768px) {
    flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  }
`;
export const Column = styled.div<IBoxProps>`
  flex: ${(props) => (props.flex ? props.flex : "initial")};
  flex-direction: column;
  align-content: ${(props) =>
    props.alignContent ? props.alignContent : "initial"};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "initial"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  width: ${(props) => (props.width ? props.width : "auto")};
`;
