import React from "react";
import styled from "styled-components";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const BurgerButton = ({ open, setOpen }: IProps) => {
  return (
    <StyledBurgerButton open={open}>
      <div
        onClick={() => setOpen(!open)}
        className={`hamburger hamburger--emphatic${open ? " is-active" : ""}`}
      >
        <div className="hamburger-box">
          <div className="hamburger-inner"></div>
        </div>
      </div>
    </StyledBurgerButton>
  );
};

const StyledBurgerButton = styled.div<{ open: boolean }>`
  position: fixed;
  top: 2.5vw;
  right: calc(6vw - 15px);
  height: 55px;
  width: 55px;
  z-index: 20;

  button {
    all: unset;
  }

  .hamburger {
    padding: 15px 15px;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }
  .hamburger:hover {
    /* opacity: 0.7; */
  }
  .hamburger.is-active:hover {
    /* opacity: 0.7; */
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #fff;
  }

  .hamburger-box {
    width: 35px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 25px;
    height: 2px;
    background-color: #fff;
    /* border-radius: 4px; */
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block;
  }
  .hamburger-inner::before {
    top: -8px;
  }
  .hamburger-inner::after {
    bottom: -8px;
  }

  .hamburger--emphatic {
    overflow: hidden;
  }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in;
  }
  .hamburger--emphatic .hamburger-inner::before {
    left: 0;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
      top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
  }
  .hamburger--emphatic .hamburger-inner::after {
    top: 8px;
    right: 0;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
      top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
  }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
  }
  .hamburger--emphatic.is-active .hamburger-inner::before {
    left: -80px;
    top: -80px;
    transform: translate3d(80px, 80px, 0) rotate(45deg);
    transition: left 0.125s ease-out, top 0.05s 0.125s linear,
      transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .hamburger--emphatic.is-active .hamburger-inner::after {
    right: -80px;
    top: -80px;
    transform: translate3d(-80px, 80px, 0) rotate(-45deg);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear,
      transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

export default BurgerButton;
