import React, {
  useEffect,
  useState,
  createContext,
  MouseEvent,
  ReactNode,
} from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { GlobalStyle } from "./globalStyle";
import { MainWrapper } from "./layoutComponents";
import { navigate } from "gatsby";

import Header from "./Header/Header";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

interface IChildren {
  children: ReactNode;
}
interface AnimContext {
  anim: boolean;
  setAnim: (anim: boolean) => void;
}
interface IContext extends IChildren {
  value: AnimContext;
}

export const StoreContext = createContext<AnimContext>({
  anim: false,
  setAnim: () => {},
});

const StoreProvider = ({ children, ...other }: IContext) => {
  return <StoreContext.Provider {...other}>{children}</StoreContext.Provider>;
};

const Layout = ({ children }: IChildren) => {
  const { pathname } = useLocation();
  const [anim, setAnim] = useState(true);
  const [isProject, setIsProject] = useState(false);

  const handleClick = (link: string) => (e: MouseEvent) => {
    e.preventDefault();
    if (link !== window.location.pathname) setAnim(true);

    if (link) {
      setTimeout(() => {
        navigate(link);
      }, 100);
    }
  };

  useEffect(() => {
    setAnim(true);
    setTimeout(() => {
      setAnim(false);
    }, 150);

    const segments = pathname.split("/");
    const last = segments.pop() || segments.pop(); // Handle potential trailing slash
    if (pathname.includes("work") && last !== "work") {
      setIsProject(true);
    } else {
      setIsProject(false);
    }
  }, [pathname]);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <GlobalStyle />
          <Helmet>
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <Header
            siteTitle={data.site.siteMetadata.title}
            handleClick={handleClick}
            isProject={isProject}
          />

          <MainWrapper
            className={anim ? "anim" : ""}
            scanlines={Boolean(pathname === "/")}
          >
            <StoreProvider value={{ anim, setAnim }}>
              <main>{children}</main>
            </StoreProvider>
            {/* <svg className="filter">
              <defs>
                <filter id="shake0">
                  <feTurbulence
                    id="turbulence"
                    baseFrequency="0.1"
                    numOctaves="3"
                    seed="0"
                  />
                  <feDisplacementMap
                    id="displacement"
                    in="SourceGraphic"
                    in2="noise"
                    scale="7"
                  />
                </filter>
                <filter id="shake1">
                  <feTurbulence
                    id="turbulence"
                    baseFrequency="0.1"
                    numOctaves="3"
                    seed="1"
                  />
                  <feDisplacementMap
                    id="displacement"
                    in="SourceGraphic"
                    in2="noise"
                    scale="5"
                  />
                </filter>
                <filter id="shake2">
                  <feTurbulence
                    id="turbulence"
                    baseFrequency="0.1"
                    numOctaves="3"
                    seed="2"
                  />
                  <feDisplacementMap
                    id="displacement"
                    in="SourceGraphic"
                    in2="noise"
                    scale="8"
                  />
                </filter>
                <filter id="shake3">
                  <feTurbulence
                    id="turbulence"
                    baseFrequency="0.1"
                    numOctaves="3"
                    seed="3"
                  />
                  <feDisplacementMap
                    id="displacement"
                    in="SourceGraphic"
                    in2="noise"
                    scale="6"
                  />
                </filter>
                <filter id="shake4">
                  <feTurbulence
                    id="turbulence"
                    baseFrequency="0.1"
                    numOctaves="3"
                    seed="4"
                  />
                  <feDisplacementMap
                    id="displacement"
                    in="SourceGraphic"
                    in2="noise"
                    scale="4"
                  />
                </filter>
              </defs>
            </svg> */}
          </MainWrapper>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
