import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

interface IProps {
  handleClick: (link: string) => void;
  isProject: boolean;
}
const BrandButton = ({ handleClick, isProject }: IProps) => {
  const link = isProject ? "/work" : "/";
  return (
    <StyledBrandButton isProject={isProject}>
      <Link to={link} onClick={() => handleClick(link)}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="brand__arrow"
        >
          <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
          <path fill="none" d="M0 0h24v24H0V0z"></path>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 150 130"
          className="brand__logo"
        >
          <defs>
            <clipPath id="clip-Custom_Size_1">
              <rect width="150" height="130" />
            </clipPath>
          </defs>
          <g
            id="Custom_Size_1"
            data-name="Custom Size – 1"
            clipPath="url(#clip-Custom_Size_1)"
          >
            <path
              id="Path_2"
              data-name="Path 2"
              d="M71.577,0l45.549-124.6H95.677L61.7-20.726h-.482L26.269-124.6H3.374L49.646,0Z"
              transform="translate(-0.25 127.097)"
              fill="#ffffff"
            />
            <path
              id="Path_1"
              data-name="Path 1"
              d="M71.577,0l45.549-124.6H95.677L61.7-20.726h-.482L26.269-124.6H3.374L49.646,0Z"
              transform="translate(29.75 127.097)"
              fill="#f9d342"
            />
          </g>
        </svg>
        <span>Back to Work</span>
      </Link>
    </StyledBrandButton>
  );
};

const StyledBrandButton = styled.div<{ isProject: boolean }>`
  position: fixed;
  top: 2.5vw;
  left: calc(6vw - 15px);
  height: 55px;
  width: 55px;
  z-index: 10;
  padding: 10px 12.5px;
  display: flex;
  align-items: center;
  transform: ${({ isProject }) =>
    isProject ? "translateX(20px)" : "translateX(0)"};
  transition: transform 0.4s ease-in-out;

  a {
    display: block;
    position: relative;
  }

  .brand__arrow {
    align-items: center;
    display: flex;
    height: 100%;
    position: absolute;
    right: 100%;
    width: 25px;
    fill: white;
    opacity: ${({ isProject }) => (isProject ? 1 : 0)};
    transition: opacity 0.4s ease-in-out;
  }
  .brand__logo {
    width: 100%;
    display: block;
  }

  span {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    opacity: ${({ isProject }) => (isProject ? 1 : 0)};
    transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    padding: 0;
    left: calc(3.5vw + 130px);
    top: 0;
    width: 700px;
    height: 80px;

    a {
      display: flex;
      align-items: center;
      transition: color 0.3s ease-in-out;
    }

    a:hover {
      color: var(--primary-color);
    }

    .brand__arrow {
      position: unset;
      fill: var(--primary-color);
    }

    .brand__logo {
      display: none;
    }

    span {
      display: block;
      opacity: ${({ isProject }) => (isProject ? 1 : 0)};
      transition: opacity 0.4s ease-in-out;
    }
  }
`;

export default BrandButton;
