import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #f9d342;
    --white: #FFFAFF;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-family: lato, Arial, Helvetica, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    touch-action: manipulation;
    overflow-x: hidden;
    background-color: #1d1d1d;
  }
  body {
    color: white;
    overflow-x: hidden;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  p{
    letter-spacing: 0.2px;
    font-weight: 300;
  }
  
  `;
